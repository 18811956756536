import React from "react"
import { graphql } from "gatsby"
import { useMediaQuery } from 'react-responsive';
import Img from 'gatsby-image';
import styled from 'styled-components/macro';

import Layout from "../components/layout"
import BackgroundImage from 'gatsby-background-image'

// import Image from "../components/image"
import SEO from "../components/seo"

import Fade from 'react-reveal/Fade';
// import Reveal from 'react-reveal/Reveal';


const HeaderSection = styled.section`
  background-color: #F2C9DC;
  scroll-behavior: smooth;
`;

const HeroContainer = styled.div`
  /* top: 8rem; */
  /* padding-top: 6rem; */
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-family: 'Poppins', sans-serif;
  align-items: stretch;
`;

const HeroBackgroundImg = styled.div`
  /* background-image: linear-gradient(to right, rgba(55, 55, 145, .3), transparent); */
  align-self: stretch;
  filter: drop-shadow(6px 6px 3px #733D56);
  width: 50%;
  flex-grow: 0;
  position: relative;
`;

const HeroContent = styled.div`
  width: 50%;
  flex-grow: 0;
  position: relative;
  padding: 2rem;
`;

const LogoContainer = styled.div`
  margin-top: 5rem;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  max-height: 100%;

  @media only screen and (min-width: 900px) {
    max-width: 90%;
  }
`;

const HeroNav = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const HeroNavItem = styled.li`
  margin: 0;
  padding: 0;
  display: list-item;
  
  a {
    margin: .5rem 0;
    padding: .5rem 1rem;
    font-size: 2.2rem;
    font-weight: 900;
    letter-spacing: .35rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .15s;

    @media only screen and (min-width: 600px) {
      font-size: 2.4rem;
      letter-spacing: .4rem;
    }

    &:hover {
      color: #F482B7;
      transform: scale(1.02) translateX(6px) rotateZ(-4deg);
      transform-origin: left;
    }
  }

  .tape-background {
    display: inline-block;
    background-color: #2c2c2c;
    color: white;
  }

`;

const FeaturedContentWrapper = styled.div`
  margin-top: 3rem;
  min-height: 15vh;
  width: 100%;
  background-color: #f2c9dc;
  padding: 2rem 3rem;

  scroll-behavior: smooth;

  @media only screen and (min-width: 900px) {
    width: 50%;
    margin-left: auto;
    background-color: transparent;
    padding: 1rem 2rem;
  }
`;

const FeaturedContentCard = styled.div`
  margin: 0 auto;
  width: 80%;
  background-color: #FFEDF5;
  box-shadow: 3px 3px 4px #B394A2;
  position: relative;
  font-size: 1.6rem;
  font-weight: 300;

  
  @media only screen and (min-width: 900px) {
    box-shadow: 3px 3px 4px #E0B3A8;
    width: 65%;
    margin: 0;
  }
  
  &:hover .boxTopHeader {
    transform: rotateZ(-4deg);
    transform-origin: bottom left;
  }

  .wrapper {
    padding: 3rem;
    /* position: relative; */
  }

  .cta-link {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    margin: 2rem 0 0 1rem;
    text-decoration: underline;
    font-size: 2rem;
    color: black;
  }

  .beeHolder {
    display: none;
    @media (orientation: landscape) {
      display: block;
      position: absolute;
      right: 1rem;
      height: 45%;
      width: 40%;
      /* background-color: rgba(45, 121, 44, .2); */
      bottom: 2rem;
    }

  }

`;

const FeaturedContentHeader = styled.h4`
  background-color: #CC14C1;
  color: #fff;
  font-size: 2.2rem;
  letter-spacing: .3rem;
  text-align: center;
  margin-left: -10%;
  font-weight: 700;
  text-transform: uppercase;
  padding: .5rem;
  position: relative;
  width: 120%;
  transition: all .3s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 4rem;
    width: 4rem;
    background-color: #CC14C1;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
  }
`;

const ProductsSection = styled.section`
  background-color: #D9A7D0;
  padding-top: 2rem;
  box-shadow: 0 -6px 10px rgba(45, 45, 45, .07);
  
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    justify-content: space-evenly;
    align-items: stretch;
    position: relative;
  }

  .cardHolder {
    
    @media only screen and (min-width: 900px) {
        /* height: 100%; */
      }

    flex: 1 0 100%;
    position: relative;

    .react-reveal {

      @media only screen and (min-width: 900px) {
        height: 100%;
      }
    }

    @media only screen and (min-width: 900px) {
      flex: 1 0 30%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
`;

const ProductCard = styled.div`
  background-color: #F2C9D1;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;
  /* padding: 1rem 2rem; */
  position: relative;
  box-shadow: 4px 4px 4px #C799BF;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: 900px) {
    display: grid;
    grid-template-rows: 2fr 1fr;
  }



  &:hover h4 {
    /* letter-spacing: .4rem; */
    transform: translateY(-10px) rotateZ(6deg);
  }

  .product-image-wrapper {
    background-color: rgba(225, 225, 225, .1);
    grid-row: 1 / 2;
    width: 100%;
    /* flex: 0 1 100%; */
    /* margin: 0 auto; */
    display: grid;
    align-items: center;
  }

  .product-info {
    background-color: rgba(225, 225, 225, .8);
    padding-bottom: 1rem;
    flex: 1 0 100%;

    h2 {
      text-transform: uppercase;
      letter-spacing: .1rem;
      width: 100%;
      margin-top: 2rem;
      /* background-color: orange; */
      text-align: center;
      padding: 1rem 0;
      font-weight: 900;
      font-size: 2.2rem;
    }

    p {
      padding: 1rem 3rem;
      font-size: 1.4rem;
    }
  }
`;

const ProductCardHeader = styled.h4`
  background-color: #CC14C1;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .3rem;
  font-weight: 700;
  font-size: 2.2rem;
  display: inline-block;
  position: absolute;
  top: -1.1rem;
  right: 1.4rem;
  padding: .5rem 1rem;
  transform: rotateZ(6deg);
  transition: all .2s;
`;

const AboutSection = styled.section`
  background-color: #ffecf4;
  color: #333;
  font-weight: 700;
  font-size: 2.2rem;
  box-shadow: 0 -10px 3px rgba(45, 45, 45, .05);
  position: relative;
  padding: 0;

  `;

const AboutSectionTopic = styled.div`

  width: 100%;
  padding-top: 4rem;
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;

  .column {
    flex: 0 0 100%;
    position: relative;

    @media only screen and (min-width: 900px) {
      flex: 0 0 50%;
    }
  }

  .card-column {
    flex: 0 0 75%;
    margin: 0 auto;
    top: -4rem;
    position: relative;
    margin-top: 3rem; 
    
    @media only screen and (min-width: 900px) {
      flex: 0 0 50%;
      margin: 0;
    }
  }

.left-text-bio {
  display: none;
  @media only screen and (min-width: 900px) {
      display: block;
    }
}

.bottom-text-bio {
  display: block;
  @media only screen and (min-width: 900px) {
      display: none;
    }
}

.column,
.card-column {


  h2 {
    font-size: 2.2rem;
    font-weight: 900;
    letter-spacing: .3rem;
    margin-bottom: 3rem;
    text-transform: uppercase;

    @media only screen and (min-width: 900px) {
      font-size: 3.2rem;
    }
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 6rem;

    @media only screen and (min-width: 900px) {
      font-size: 2.2rem;
    }
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 400;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
  }
}

  .margin-right {
    @media only screen and (min-width: 600px) {
      margin-right: 5rem;
    }
  }

  .margin-left {
    @media only screen and (min-width: 600px) {
      margin-left: 8rem;
    }
  }
  .pad-top {
    padding-top: 0;
    
    @media only screen and (min-width: 600px) {
      padding-top: 16rem;
    }
  }

    .align-center {
      margin: auto;
    }

  .wrapper-card {
    position: relative;
    background-color: #fff;
    padding: 3rem 2rem;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    
    @media only screen and (min-width: 900px) {
      margin: 0 6rem;
      padding: 5rem 6rem;
    }
  }

  .image-with-text {
    flex: 1 0 100%;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: 900px) {
      padding-bottom: 2rem;
    }
  }

  .right-side {
    @media only screen and (min-width: 900px) {
       left: -8.5rem;
       top: 50%;
       transform: translateY(-50%);
    }
  }

  .left-side {


    @media only screen and (min-width: 900px) {
       left: 10.5rem;
       top: 50%;
       transform: translateY(calc(-50% - 4rem));
       z-index: 2;
    }

    @media only screen and (min-width: 1250px) {
      /* flex: 1 0 60%;  */
      left: 8.5rem;
       top: 50%;
       transform: translateY(calc(-50% - 4rem));
       z-index: 2;
    }
  }

  .image-holder-left {
    @media only screen and (min-width: 900px) {
       margin-left: 8.5rem;
       padding-top: 4rem;
    }
  }

  .image-holder-right {
    @media only screen and (min-width: 900px) {
       
       padding-bottom: 8rem;
    }
  }

  .circle-img {
    img {
      border-radius: 50%;
    }
  }
`;


export const logoQuery = graphql`
  query {
    logoImage: file(relativePath: {eq: "WizWorksLogo.png"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroImage: file(relativePath: {eq: "bees_flowers_cookies-removebg-full.png"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beeOnlyImage: file(relativePath: {eq: "bee_no_background.png"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beeCartoon: file(relativePath: {eq: "bee_cartoon_no_background.png"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medicalCookies: file(relativePath: {eq: "medical_cookies-nobg.png"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    valentinePackage: file(relativePath: {eq: "valentine_package_cropped.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drinkCookies: file(relativePath: {eq: "drink_cookies_cropped.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flowersCookies: file(relativePath: {eq: "flowers_cookies.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sweet16: file(relativePath: {eq: "sweet16_cookies-nobg.png"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  // const isPortraitMode = useMediaQuery({ query: '(orientation: portrait)' });
  // const is900pxWide = useMediaQuery({ query: '(min-width: 900px)' });

  return (
    <Layout>
      <SEO title="Home" />
      <HeaderSection>

        <HeroContainer >
          <HeroBackgroundImg >
            {/* <Img fluid={props.data.heroImage.childImageSharp.fluid} imgStyle={{height: '100%', objectFit: 'cover'}} /> */}
            <BackgroundImage
              Tag="div"
              // className={className}
              fluid={props.data.heroImage.childImageSharp.fluid}
              // backgroundColor={`#040e18`}
              style={{ height: '100%', backgroundPosition: '100% 0', backgroundSize: 'cover' }}
            >
            </BackgroundImage>
          </HeroBackgroundImg>
          <HeroContent>
            <LogoContainer>
              <Img fluid={props.data.logoImage.childImageSharp.fluid} imgStyle={{}} />
            </LogoContainer>
            <HeroNav>
              <HeroNavItem><a className="tape-background" href="#services">Services</a></HeroNavItem>
              <HeroNavItem><a className="tape-background" href="#slogan">About</a></HeroNavItem>
              <HeroNavItem><a className="tape-background" href="#kudos">Kudos</a></HeroNavItem>
              <HeroNavItem><a className="tape-background" href="#contact">Contact </a></HeroNavItem>
            </HeroNav>
          </HeroContent>
          <FeaturedContentWrapper>
            <Fade bottom>

              <FeaturedContentCard >
                <FeaturedContentHeader className="boxTopHeader">
                  Give A Gift
            </FeaturedContentHeader>
                <div className="wrapper">
                  <p>
                    WizWorks custom gift packages deliver smiles for birthday parties, corporate events, holiday celebrations, or any occasion!
              </p>

                  <a className="cta-link" href="mailto:wizworksllc@gmail.com?subject=WizWorks Contact" target="_blank" rel="noopener noreferrer">Get yours now</a>
                    <div className="beeHolder">
                      <Img fluid={props.data.beeCartoon.childImageSharp.fluid} imgStyle={{ height: '100%', objectFit: 'cover' }} />
                    </div>
                </div>
              </FeaturedContentCard>
            </Fade>
          </FeaturedContentWrapper>
        </HeroContainer>
      </HeaderSection>

      <ProductsSection id="services">
        <div className="wrapper">
          <div className="cardHolder">
            <Fade bottom>
              <ProductCard>
                <ProductCardHeader>
                  Indulge
                </ProductCardHeader>
                <div className="product-image-wrapper">
                  <Img fluid={props.data.beeOnlyImage.childImageSharp.fluid} imgStyle={{}} />
                </div>
                <div className="product-info">
                  <h2>
                    Sweet Snacks
                  </h2>
                  <p>
                    Personally-crafted treats to satisfy your cravings and delight your sweet tooth.
                  </p>
                  <p>
                    Available flavors include Shortbread / Sugar, Peanut Butter, Chocolate, Honey Graham, and Chocolate Chip.
                  </p>
                </div>
              </ProductCard>
            </Fade>
          </div>

          <div className="cardHolder">
            <Fade bottom>
              <ProductCard>
                <ProductCardHeader>
                  Share
                </ProductCardHeader>
                <div className="product-image-wrapper">
                  <Img fluid={props.data.medicalCookies.childImageSharp.fluid} imgStyle={{}} />
                </div>
                <div className="product-info">
                  <h2>
                    Spread Cheer
                  </h2>
                  <p>
                    Packages designed for small groups, gatherings, brunches, and book clubs (or anywhere else)!
                  </p>
                  <p>WizWorks cookies add the perfect personal touch that makes a great gift for any occasion.</p>
                </div>
              </ProductCard>
            </Fade>
          </div>

          <div className="cardHolder">
            <Fade bottom>
              <ProductCard>
                <ProductCardHeader>
                  Party
                </ProductCardHeader>
                <div className="product-image-wrapper">
                  <Img fluid={props.data.sweet16.childImageSharp.fluid} imgStyle={{}} />
                </div>
                <div className="product-info">
                  <h2>
                    Showstoppers
                  </h2>
                  <p>
                    Birthdays, weddings, anniversaries, holidays... commemorate any event with custom cookies.
                  </p>
                  <p>
                    Packages for large groups are available and will be remembered by all your attendees.
                  </p>
                </div>
              </ProductCard>
            </Fade>
          </div>
        </div>
      </ProductsSection>
      <AboutSection>
        <AboutSectionTopic>
          <Fade bottom>
            <div className="image-with-text" id="slogan">
              <div className="column">
                <div className="image-holder-left">
                  <Img fluid={props.data.valentinePackage.childImageSharp.fluid} imgStyle={{}} />
                </div>
              </div>
              <div className="card-column">
                <div className="wrapper-card right-side">
                  <h2>
                    A little cookie company with a lot of heart.
              </h2>
                  <p>
                    Homemade custom-designed, decorated cookies. Perfect for your next birthday, holiday, anniversary, or event.
              </p>
                </div>
              </div>
            </div>
          </Fade>
        </AboutSectionTopic>
        <AboutSectionTopic>
          <Fade bottom>
            <div className="image-with-text">
                <div className="column">
                  <div className="wrapper-card left-text-bio left-side">
                    <h3 className="align-center">
                      I’m Wendi Johnson, the creator of WizWorks Cookie Company. I am passionate about creating beautiful cookies. There’s nothing better than spreading smiles with a cookie!
              </h3>
                    <p>After spending years in a high-stress career, baking became my creative outlet. The WizWorks brand was created during my career and carried into the cookie biz when I decided to leave the stress behind and bake full time!  </p>
                  </div>
                </div>

              <div className="column">
                <div className="image-holder-right">
                  <Img fluid={props.data.drinkCookies.childImageSharp.fluid} imgStyle={{}} />
                </div>
              </div>

                <div className="card-column">
                  <div className="wrapper-card bottom-text-bio">
                    <h3 className="align-center" style={{ textTransform: 'uppercase' }}>
                      I’m Wendi Johnson, the creator of WizWorks Cookie Company.
                </h3>
                    <p>After spending years in a high-stress career, baking became my creative outlet. The WizWorks brand was created during my career and carried into the cookie biz when I decided to leave the stress behind and bake full time!  </p>
                  </div>
                </div>

            </div>
          </Fade>
        </AboutSectionTopic>

        <AboutSectionTopic id="kudos">
          <Fade bottom>
            <div className="image-with-text">
              <div className="column">
                <div className="image-holder-left circle-img">
                  <Img fluid={props.data.flowersCookies.childImageSharp.fluid} imgStyle={{ filter: 'drop-shadow(2px 2px 6px #333' }} />

                </div>
              </div>
              <div className="card-column">
                <div className="wrapper-card right-side">
                  <h3 className="margin-right">
                    <strong>&ldquo;</strong>Thank you so much for the cookies!! You did an amazing job and my husband loves them!<strong>&rdquo;</strong>
                  </h3>

                  <h4 className="margin-left">
                    <strong>&ldquo;</strong>Absolutely SCRUMPTIOUS and beautiful cookies and confections!  There is love and care baked into every bite!<strong>&rdquo;</strong>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>
        </AboutSectionTopic>
      </AboutSection>
    </Layout>
  )
}

export default IndexPage
